import React from 'react';

import { About, Footer, Header, Skills, Work } from './container';
//importing all container as a whole from the index.js file in container folder
import { Navbar } from './components';
import './App.scss';


const App = () => {
  return (
    <div className="app">
      <Navbar/>
      <Header/>
      <About/>
      <Work/>
      <Skills/>
      <Footer/>
    </div>
  );
}

export default App;