import React from 'react';
import { BsTwitter, BsLinkedin } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';

const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
            <a href='#'><BsTwitter/></a>
        </div>
        <div>
        <a href="https://www.linkedin.com/in/safal-sharma-poudel-bba791141/" target="_blank"><BsLinkedin/></a>
        </div>
        <div>
            <BsFacebook/>
        </div>
    </div>
  )
}

export default SocialMedia