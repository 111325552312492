import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
//importing all images with simple code by the grace of image.js and index.js file in constants folder
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <nav className="app__navbar">
      {/* '__' is used for BEM methodology simply google it for better understanding */}
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {/*items in nav menu in the form of array */}
        {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div></div>
            <a href={`#${item}`}>{item}</a>
            {/* this will leads to the specific portion of the page */}
          </li>
        )) }
      </ul>

      <div className="app__navbar-menu"> {/* This is more mobile nav bar three line icon */}
          <HiMenuAlt4 onClick={() => setToggle(true)}/>

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut'}}
          >
            <HiX onClick={() => setToggle(false)}/>
            <ul>
              {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}> {item} </a>
                     {/* this will leads to the specific portion of the page */}
                </li>
              )) }
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;